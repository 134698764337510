import request from '../axiosInterceptor'

const api = {
  getCompanions() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/companion-management/companion-groups-list`,
      method: 'get',
    })
  },
  getCompanionDashboard(query: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/companion-management/dashboard${query}`,
      method: 'get',
    })
  },
  getCompanionsOrganisations() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/companion-management/get-organization`,
      method: 'get',
    })
  },
  getCompanionProjects(id: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/companion-management/get-active-projects?subsidiary_master_id=${id}`,
      method: 'get',
    })
  },
  getCompanionModules(id: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/companion-management/module-types?subsidiary_master_id=${id}`,
      method: 'get',
    })
  },
  getCompanionPrivateGroups(id: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/companion-management/get-private-groups?subsidiary_master_id=${id}`,
      method: 'get',
    })
  },
  getCompanionDetails(groupId: string, subsidiaryId: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/companion-management/companion-details?group_external_id=${groupId}&subsidiary_master_id=${subsidiaryId}`,
      method: 'get',
    })
  },
  postEditCompanion(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/companion-management/activate-or-edit-companion`,
      method: 'post',
      data: data,
    })
  },
}

export default api
