import LoginModule from './modules/LoginModule'
import AdminUsersModule from './modules/AdminUsersModule'
import OrganisationModule from './modules/OrganisationModule'
import ThemeModule from './modules/ThemeModule'
import MasterModule from './modules/MasterModule'
import CompanionModule from './modules/CompanionModule'

const api = {
  ...LoginModule,
  ...AdminUsersModule,
  ...OrganisationModule,
  ...ThemeModule,
  ...MasterModule,
  ...CompanionModule,
}
export default api
