import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { isAuthenticated } from '../hooks/CookiesHook'

// Lazy load pages
const PageNotFound = lazy(() => import('../pages/PageNotFound'))
const Login = lazy(() => import('../pages/Login'))
const OTPVerification = lazy(() => import('../pages/OTPVerification'))
const Dashboard = lazy(() => import('../pages/Dashboard'))
// Admin users
const AdminUsers = lazy(() => import('../pages/AdminUsers'))
const AddAdminUser = lazy(() => import('../pages/AdminUsers/AddAdminUser'))
// Organisation management
const OrganisationManagement = lazy(() => import('../pages/OrganisationManagement'))
const OrganisationDetails = lazy(() => import('../pages/OrganisationManagement/OrganisationDetails'))
const AddOrganisation = lazy(() => import('../pages/OrganisationManagement/AddOrganisation'))
const AddSubsidiary = lazy(() => import('../pages/OrganisationManagement/OrganisationSubsidiary/AddSubsidiary'))
const OrganisationUserDetail = lazy(() => import('../pages/OrganisationManagement/OrganisationUsers/OrganisationUserDetail'))
const OrganisationSubsidiaryUsers = lazy(() => import('../pages/OrganisationManagement/OrganisationSubsidiary/OrganisationSubsidiaryUsers'))
// Theme management
const ThemeManagement = lazy(() => import('../pages/ThemeManagement'))
const ThemeAdd = lazy(() => import('../pages/ThemeManagement/ThemeAdd'))
// Companion management
const CompanionManagement = lazy(() => import('../pages/CompanionManagement'))
const CompanionDetails = lazy(() => import('../pages/CompanionManagement/CompanionDetails'))

// Lazy load components
const DashboardLayout = lazy(() => import('../components/UI/Layouts/DashboardLayout'))
const ComingSoon = lazy(() => import('../components/ComingSoon'))

// if already authenticated and try to access login page, redirect to dashboard
const PublicRoute = ({ children }: any) => {
  return isAuthenticated() ? <Navigate to="/dashboard" /> : children
}

// if not authenticated and try to access protected page, redirect to login
const ProtectedRoute = ({ children }: any) => {
  return isAuthenticated() ? children : <Navigate to="/login" />
}

const wrapRoutesWithProtectedRoute = (routes: any) => {
  // Map over the routes array
  return routes.map((route: any) => {
    // Check if the route has children
    if (route.children) {
      // If it has children, recursively wrap the children with protected route
      const children = wrapRoutesWithProtectedRoute(route.children)
      return {
        ...route,
        children,
      }
    } else {
      // If the route does not have children, determine whether to wrap it with protected or public route
      return {
        ...route,
        element: route?.private ? <ProtectedRoute key={route.path}>{route.element}</ProtectedRoute> : <PublicRoute key={route.path}>{route.element}</PublicRoute>,
      }
    }
  })
}

export const routes = [
  ...wrapRoutesWithProtectedRoute([
    {
      path: '',
      private: false,
      element: <Login />,
    },
    {
      path: 'login',
      private: false,
      element: <Login />,
    },
    {
      path: 'otp-verification',
      private: false,
      element: <OTPVerification />,
    },
    {
      path: 'dashboard',
      private: true,
      element: (
        <DashboardLayout>
          <Dashboard />
        </DashboardLayout>
      ),
    },
    {
      path: 'user',
      private: true,
      element: (
        <DashboardLayout>
          <AdminUsers />
        </DashboardLayout>
      ),
    },
    {
      path: 'user/add',
      private: true,
      element: (
        <DashboardLayout>
          <AddAdminUser />
        </DashboardLayout>
      ),
    },
    {
      path: 'user/edit',
      private: true,
      element: (
        <DashboardLayout>
          <AddAdminUser />
        </DashboardLayout>
      ),
    },
    {
      path: 'bots',
      private: true,
      element: (
        <DashboardLayout>
          <ComingSoon />
        </DashboardLayout>
      ),
    },
    {
      path: 'theme',
      private: true,
      element: (
        <DashboardLayout>
          <ThemeManagement />
        </DashboardLayout>
      ),
    },
    {
      path: 'theme/add',
      private: true,
      element: (
        <DashboardLayout>
          <ThemeAdd />
        </DashboardLayout>
      ),
    },
    {
      path: 'theme/:name/:id',
      private: true,
      element: (
        <DashboardLayout>
          <ThemeAdd mode="view" />
        </DashboardLayout>
      ),
    },
    {
      path: 'organisation',
      private: true,
      element: (
        <DashboardLayout>
          <OrganisationManagement />
        </DashboardLayout>
      ),
    },
    {
      path: 'organisation/details',
      private: true,
      element: (
        <DashboardLayout>
          <OrganisationDetails />
        </DashboardLayout>
      ),
    },
    {
      path: 'organisation/add',
      private: true,
      element: (
        <DashboardLayout>
          <AddOrganisation />
        </DashboardLayout>
      ),
    },
    {
      path: 'organisation/details/user',
      private: true,
      element: (
        <DashboardLayout>
          <OrganisationUserDetail />
        </DashboardLayout>
      ),
    },
    {
      path: 'organisation/details/subsidiary-users',
      private: true,
      element: (
        <DashboardLayout>
          <OrganisationSubsidiaryUsers />
        </DashboardLayout>
      ),
    },
    {
      path: 'organisation/details/add-subsidiary',
      private: true,
      element: (
        <DashboardLayout>
          <AddSubsidiary />
        </DashboardLayout>
      ),
    },
    {
      path: 'organisation/details/edit-subsidiary',
      private: true,
      element: (
        <DashboardLayout>
          <AddSubsidiary />
        </DashboardLayout>
      ),
    },
    // Companion
    {
      path: 'companion-management',
      private: true,
      element: (
        <DashboardLayout>
          <CompanionManagement />
        </DashboardLayout>
      ),
    },
    {
      path: 'companion-management/:name/:id/:subName/:subId',
      private: true,
      element: (
        <DashboardLayout
          breadcrumbData={[
            { title: 'Companion Management', redirect: '/companion-management' },
            { title: ':name', redirect: '/companion-management' },
          ]}
        >
          <CompanionDetails />
        </DashboardLayout>
      ),
    },
    {
      path: 'masters',
      private: true,
      element: (
        <DashboardLayout>
          <ComingSoon />
        </DashboardLayout>
      ),
    },
  ]),
  {
    path: '*',
    element: <PageNotFound />,
  },
]
